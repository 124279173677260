export const countries = [
  { value: "México", label: "México" },
  { value: "Colombia", label: "Colombia" },
  { value: "Argentina", label: "Argentina" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Perú", label: "Perú" },
  { value: "Chile", label: "Chile" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Cuba", label: "Cuba" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "República Dominicana", label: "República Dominicana" },
  { value: "Honduras", label: "Honduras" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Panamá", label: "Panamá" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Guinea Ecuatorial", label: "Guinea Ecuatorial" },
  { value: "España", label: "España" },
  { value: "Estados Unidos", label: "Estados Unidos" },
  { value: "Canadá", label: "Canadá" },
];
