import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "dayjs/locale/es";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);


const localTimeZone = new Date().getTimezoneOffset() / 60; //offset in hours

export const formatDate = (date) => {
  let result = new Date(date);
  result.setTime(result.getTime() + localTimeZone * 60 * 60 * 1000);
  const formatDate = dayjs(result).format("DD/MM/YYYY");
  return formatDate;
};

export const formatDateForCreateTraining = (date) => {
  const americanFormat = dayjs(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
  let result = new Date(americanFormat);
  result.setTime(result.getTime() + localTimeZone * 60 * 60 * 1000);
  const formatDate = dayjs(result);
  return formatDate;
};

export const formatDateForCreateTrainingFormCalendar = (date) => {
  const americanFormat = dayjs(date).format('YYYY-MM-DD');
  let result = new Date(americanFormat);
  result.setTime(result.getTime() + localTimeZone * 60 * 60 * 1000);
  const formatDate = dayjs(result);
  return formatDate;
};

export const formatDateForInputDefaultValues = (date) => {
  let result = new Date(date);
  result.setTime(result.getTime() + localTimeZone * 60 * 60 * 1000);
  const formatDate = dayjs(result).format("YYYY-MM-DD");
  return formatDate;
};

export const formatDateWithDayJS = (date) => {
  const dateformat = dayjs(date).format("DD/MM/YYYY");
  return dateformat;
};

export const formatDateForQuery = (date) => {
  const localDate = dayjs(date).local();
  const formatDay = localDate.format("DD");
  const formatMonth = localDate.format("MM");
  const formatYear = localDate.format("YYYY");
  return {
    formatDay,
    formatMonth,
    formatYear,
  };
};

export const firstDayOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');

export const isUnder18 = (birthdate) => {
  const currentYear = dayjs().year();
  const birthYear = dayjs(birthdate).year();
  const age = currentYear - birthYear;
  return age < 13;
}


export const isDocumentUpdated = (createdAt, updatedAt) => {
  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt);

  return updatedDate > createdDate;
}

export const isAfterJuly2024 = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return currentYear > 2024 || (currentYear === 2024 && currentMonth > 6); //TODO: cambiar para depliy
};
