import { useContext, useEffect } from "react";
import { useUpdateTrainingMutation } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { adaptSeriesCreateTraining } from "../../adapters/exerciseFormat";
import { TypesOfTraining, isBlockTraining, isSchduledTraining, isSeriesTraining } from "../../assets/data/typesOfTrainings";
import { useState } from "react";
import { useGetMyProfile } from "../users/useGetMyProfile";
import dayjs from "dayjs";
import { formatDateForCreateTraining } from "../../helpers/formatDate";
import { LanguageContext } from "../../context/LanguageContext";

export function useUpdateTraining(training, closeModal) {
  const { lang } = useContext(LanguageContext);
  const { sports: dataSports } = useGetMyProfile();

  const [updateTraining, { isError: isErrorUpdate, error: errorUpdate, isLoading: isLoadingUpdateTraining }] = useUpdateTrainingMutation();

  const [formUpdateTraining, handleUpdateTraining, reset] = useForm({
    title: training?.title,
    target: training?.target,
    typesOfTraining: training?.typesOfTraining,
    seriesTemp: training?.optionsSeriesTraining?.series,
    optionsSeriesTraining: training?.optionsSeriesTraining,
  });

  const [sportInStore, setSportInStore] = useState([]);
  const [sports, setSports] = useState([]);

  useEffect(() => {
    if (dataSports) {
      setSportInStore(dataSports);

      const sports = sportInStore?.map((sport) => {
        return {
          value: sport.toLowerCase(),
          name: sport,
        };
      });

      setSports(sports);
    }
  }, [dataSports, sportInStore]);

  const [valueSports, setvalueSports] = useState(training?.sports);

  const { title, target, typesOfTraining, seriesTemp } = formUpdateTraining;

  const [aSingleTrainingDate, setASingleTrainingDate] = useState(dayjs(training?.startDate).format('DD-MM-YYYY'));

  const handleASingleTrainingDate = (date) => {
    setASingleTrainingDate(dayjs(date).format('DD-MM-YYYY'));
    // setIsRequiredDate(false)
  };

  const handleUpdateTrainingContainer = async (query) => {

    const startDateTemp = aSingleTrainingDate === "" ? training?.startDate : formatDateForCreateTraining(aSingleTrainingDate) //formatDateForCreateTraining(startDate)

    const { activateSeriesTemp, seriesTemp, ...newCleanForm } = formUpdateTraining;

    let updateTrainingData;

    if (isSchduledTraining(typesOfTraining)) {
      updateTrainingData = {
        ...newCleanForm,
        sports: valueSports,
        startDate: startDateTemp,
        typesOfTraining: TypesOfTraining.SchduledTraining,
      }
    }

    if (isBlockTraining(typesOfTraining)) {
      updateTrainingData = {
        ...newCleanForm,
        sports: valueSports,
        startDate: startDateTemp,
        typesOfTraining: TypesOfTraining.BlockTraining,
      }
    }

    if (isSeriesTraining(typesOfTraining)) {
      updateTrainingData = {
        ...newCleanForm,
        sports: valueSports,
        optionsSeriesTraining: {
          ...formUpdateTraining.optionsSeriesTraining,
          series: adaptSeriesCreateTraining(seriesTemp)
        },
        startDate: startDateTemp,
        typesOfTraining: TypesOfTraining.SeriesTraining,
      }
    }


    await updateTraining({ trainingId: training._id, query, dataUpdated: updateTrainingData }).unwrap()
      // .then(() => loadingAlert(lang.alerts.updatingWorkout))
      .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
    Swal.close();
    reset();
    closeModal()
  };

  const initialFormState = {
    title: training?.title,
    target: training?.target,
    typesOfTraining: training?.typesOfTraining,
    seriesTemp: training?.optionsSeriesTraining?.series,
    optionsSeriesTraining: training?.optionsSeriesTraining,
    sports: training?.sports,
    startDate: dayjs(training?.startDate).format('DD-MM-YYYY')
  };

  const hasFormChanged = () => {
    const currentFormState = {
      title,
      target,
      typesOfTraining,
      seriesTemp,
      optionsSeriesTraining: formUpdateTraining.optionsSeriesTraining,
      sports: valueSports,
      startDate: aSingleTrainingDate
    };

    return JSON.stringify(initialFormState) !== JSON.stringify(currentFormState);
  };

  const isSaveButtonDisabled = !hasFormChanged();

  useEffect(() => {
    if (isErrorUpdate) showAlert(errorUpdate?.data?.msgCode, errorUpdate?.data?.ok);
  }, [errorUpdate?.data?.msgCode, errorUpdate?.data?.ok, isErrorUpdate]);

    useEffect(() => {
      if (isLoadingUpdateTraining) {
          loadingAlert(lang.alerts.updatingWorkout)
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdateTraining])

  return {
    title,
    handleUpdateTraining,
    sports,
    valueSports,
    setvalueSports,
    target,
    seriesTemp,
    aSingleTrainingDate,
    handleASingleTrainingDate,
    handleUpdateTrainingContainer,
    isSaveButtonDisabled
  };
}

