import React, { useContext } from 'react'
import { Options } from '../selects/Options'
import { AgeRange } from '../ageRange/AgeRange'
import { Button } from 'react-bootstrap'
import { LanguageContext } from '../../context/LanguageContext'
import { formatDate } from '../../helpers/formatDate'
import { useGetMyProfile, useProfileUpdate } from '../../hooks'
import { useSlicesState } from '../../redux/slices'
import { countries } from '../../assets/data/countries'

export const AboutMe = ({activeToggle, aboutMeRef}) => {

    const { lang } = useContext(LanguageContext);
    const { editingProfile } = useSlicesState()

    const {
        isAthlete,
        yearsOfExperience,
        birthdate: actualBirthdate,
        aboutMe: actualAboutMe,
        country: actualCountry,
      } = useGetMyProfile()
    

    const {
        aboutMe, 
        birthdate, 
        country,
        handleFormProfileValues,
        handleStartEditing,
        handleUpdateProfile,
        handleCancelEdit,
        ageRange,
        setAgeRange
      } = useProfileUpdate()


    return (
        <div
            id="about-me"
            className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""
                }`}
        >
            <div className="profile-about-me">
                <div className="pt-4 border-bottom-1 pb-3">
                    <h4 className="text-primary">{lang.profile.aboutMe}</h4>

                    {!editingProfile && !actualAboutMe && (
                        <p className="mb-2">{lang.profile.incompleteProfile}</p>
                    )}

                    {!editingProfile && actualAboutMe && (
                        <p className="mb-2">{actualAboutMe}</p>
                    )}
                </div>
            </div>

            <form autoComplete="off">
                {editingProfile && (
                    <div className="form-group border-color-textarea">
                        <textarea
                            className="form-control "
                            rows="4"
                            id="aboutMe"
                            name="aboutMe"
                            ref={aboutMeRef}
                            value={aboutMe}
                            placeholder={aboutMe === "" ? lang.profile.completeYourBiography : ''}
                            onChange={handleFormProfileValues}
                        ></textarea>
                    </div>
                )}

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div className="profile-lang  mb-5">
                            <h4 className="text-primary mb-2">
                                {lang.profile.birthdateDate}
                            </h4>

                            {!editingProfile && !actualBirthdate && (
                                <p className="mb-2">
                                    {lang.profile.editAndFillInYourDate}
                                </p>
                            )}

                            {!editingProfile && actualBirthdate && (
                                <p className="mb-2">
                                    {formatDate(actualBirthdate)}
                                </p>
                            )}

                            {editingProfile && (
                                <input
                                    type="date"
                                    id="birthdate"
                                    name="birthdate"
                                    min="1920-01-01"
                                    value={birthdate}
                                    className="form-control border border-info"
                                    onChange={handleFormProfileValues}
                                />
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <h4 className="text-primary mb-2">
                                {lang.profile.country}
                            </h4>
                            {editingProfile && (
                                <select
                                    // defaultValue={"option"}
                                    className="form-control border border-info"
                                    name="country"
                                    value={country}
                                    onChange={handleFormProfileValues}
                                >
                                    <option value="none">
                                        {lang.generic.selectAnOption}
                                    </option>
                                    <Options options={countries} />
                                </select>
                            )}

                            {!editingProfile && !actualCountry && (
                                <p className="mb-2">
                                    {lang.profile.editAndSelectYourCountry}
                                </p>
                            )}
                            {!editingProfile && actualCountry && (
                                <p className="mb-2">{actualCountry}</p>
                            )}
                        </div>
                    </div>
                </div>

                <>
                    <div className="profile-lang  mb-5">
                        {isAthlete() ? (
                            <h4 className="text-primary mb-2">
                                {lang.profile.yearsYouHaveBeenTraining}
                            </h4>
                        ) : (
                            <h4 className="text-primary mb-2">
                                {lang.profile.yearsOfExperience}
                            </h4>
                        )}

                        {editingProfile && (
                            <AgeRange
                                ageRange={ageRange}
                                setAgeRange={setAgeRange}
                            />
                        )}

                        {!editingProfile &&
                            !yearsOfExperience && (
                                <p className="mb-2">Edita para modifiacr</p>
                            )}
                        {!editingProfile && yearsOfExperience && (
                            <p className="mb-2">
                                {yearsOfExperience}
                            </p>
                        )}

                        {!editingProfile ? (
                            <Button
                                className="float-right btn-sm"
                                variant="warning light xs"
                                onClick={() => handleStartEditing()}
                            >
                                {lang.buttons.edit}
                            </Button>
                        ) : (
                            <>
                                <Button
                                    className="float-right btn-sm"
                                    variant="secondary xs"
                                    onClick={(e) => handleUpdateProfile(e)}
                                >
                                    {lang.buttons.save}
                                </Button>

                                <Button
                                    className="float-right btn-sm mr-2"
                                    variant="danger xs"
                                    onClick={() => handleCancelEdit()}
                                >
                                    {lang.buttons.cancel}
                                </Button>
                            </>
                        )}
                    </div>
                </>
            </form>
        </div>
    )
}
