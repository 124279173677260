import React, { useContext } from "react";
//import { GymsTeamsForSearchResult } from '..';
import { LanguageContext } from "../../context/LanguageContext";

//import img from '../../assets/img/logoGym.jpg'
export const CardAboutMeDetailSearch = (userProps) => {
  const { lang } = useContext(LanguageContext);


  return (
    <>
      <div className="col-xl-4 h-100">
        <div className="card">
          <div className="card-body">
            <div className="profile-tab">
              <div className="custom-tab-1">
                <div className="tab-content">
                  <div className="profile-about-me">
                    <div className="pt-4 border-bottom-1 pb-3">
                      <h4 className="text-primary">{lang.profile.aboutMe}</h4>

                      <span className="mt-0">
                        {lang.profile.iamCountry} {userProps.country}
                      </span>

                      <p className="mb-2">{userProps.aboutMe}</p>
                    </div>

                    {/** Mis GYMS solo aparece si tiene GYMS */}
                    {/* <div className="pt-4 border-bottom-1 pb-3">
                                 <h4 className="text-primary">
                                 {lang.myGym.myInstitutes}
                                 </h4>

                                 <ul className="timeline ">
                                    {
                                       data.map((d,i) => (
                                          <GymsTeamsForSearchResult key={i} name={d.name} img={d.img}/>
                                       ))
                                    }
                                    
                                 </ul>

                              </div> */}

                    {/** Mis TEAMS solo aparece si tiene GYMS */}
                    {/* <div className="pt-4 border-bottom-1 pb-3">
                                 <h4 className="text-primary">
                                 {lang.myTeams.myTeams}
                                 </h4>

                                 <ul className="timeline ">
                                    {
                                       data.map((d,i) => (
                                          <GymsTeamsForSearchResult key={i} name={d.name} img={d.img}/>
                                       ))
                                    }
                                    
                                 </ul>

                              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
