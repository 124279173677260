import React from "react";

export const Options = ({options}) => {

  options.sort((after, before) =>
    before.name !== 'all' ? after.label.toLowerCase().localeCompare(before.label.toLowerCase()) : 0
  );

  return (
    <>
      {options.sort()?.map(({label, value}, i) => (
        <option className="text-capitalize" key={i} value={value}>{label}</option>
      ))}
    </>
  );
};
