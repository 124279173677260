import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const trainingAPI = createApi({
    reducerPath: 'trainingAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["trainingAPI"],
    endpoints: (builder) => ({
        getNumberOfWorkoutsOtherUser: builder.query({
            query: (userId) => `/training/get-number-of-workouts?userId=${userId}`,
            providesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            keepUnusedDataFor: 3800
        }),
        getTrainingFromAthleta: builder.query({
            query: ({ userId, month, year }) => `/training/get-training-from-athleta/${userId}/?month=${month}&year=${year}`,
            providesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
        }),
        createTraining: builder.mutation({
            query: (trainingData) => (
                {
                    url: `/training/create-training`,
                    method: 'POST',
                    body: trainingData
                }),
            invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
        }),
        deleteTraining: builder.mutation({
            query: ({ trainingId }) => ({
                url: `/training/delete/${trainingId}`,
                method: 'DELETE',
            }),
            // invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ trainingId, query, alertError }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = oldData?.trainings?.filter((training) => training._id !== trainingId);
                        return {
                            ...oldData,
                            trainings: updatedData
                        };
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                    alertError()
                }
            },
        }),
        updateTraining: builder.mutation({
            query: ({ trainingId, dataUpdated }) => ({
                url: `/training/update/${trainingId}`,
                method: 'PUT',
                body: dataUpdated
            }),
            // invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ trainingId, query, ...result }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = Array.from(oldData?.trainings).map((training) =>
                            training._id === trainingId ? { ...training, ...result?.dataUpdated } : training
                        );
                        return { ...oldData, trainings: updatedData };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateExerciseInTraining: builder.mutation({
            query: (dataMutation) => ({
                url: `/exercise/update/${dataMutation?.exerciseId}`,
                method: 'PUT',
                body: dataMutation?.data
            }),
            // invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ exerciseId, trainingId, query, ...result }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = Array.from(oldData?.trainings).map((training) =>
                            training._id === trainingId ? {
                                ...training,
                                exercisesId: training?.exercisesId?.map((exercise) =>
                                    exercise._id === exerciseId ? { ...exercise, ...result?.data } : exercise
                                )
                            } : training
                        );
                        return { ...oldData, trainings: updatedData };
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteExerciseInTraining: builder.mutation({ //TODO: Eliminar a futuro cuando ya no se creen entrenamientos con calendario/series
            query: ({ idTraining, idExercise }) => ({
                url: `/training/detele-exercise-in-training/${idTraining}/${idExercise}`,
                method: 'DELETE',
            }),
            // invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idExercise, idTraining, query }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = Array.from(oldData?.trainings).filter((training) =>
                            training._id !== idTraining || (training._id === idTraining && training?.exercisesId?.length !== 1)
                        ).map((training) =>
                            training._id === idTraining ? {
                                ...training,
                                exercisesId: training?.exercisesId?.filter((exercise) => exercise._id !== idExercise)
                            } : training
                        );
                        return { ...oldData, trainings: updatedData };
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getTrainingFromProfessional: builder.query({
            query: ({ userId, month, year }) => `/training/get-my-workouts-from-a-professional/${userId}/?month=${month}&year=${year}`,
            providesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
        }),
        getMyWorkout: builder.query({
            query: ({ month, year }) => `/training/get-my-workout?month=${month}&year=${year}`,
            providesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
        }),
        terminateExercise: builder.mutation({
            query: ({ idTraining, idExercise }) => ({
                url: `/exercise/terminate/${idExercise}/${idTraining}`,
                method: 'PUT',
            }),
            // invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idExercise, idTraining, idProfessional, query }, { dispatch, queryFulfilled }) => {
                const updateCache = (queryName, query) => {
                    const patchResult = dispatch(
                        trainingAPI.util.updateQueryData(queryName, query, (oldData) => {
                            const updatedData = Array.from(oldData?.trainings).map((training) =>
                                training._id === idTraining ? {
                                    ...training,
                                    exercisesId: training?.exercisesId?.map((exercise) =>
                                        exercise._id === idExercise ? {
                                            ...exercise,
                                            isComplete: true
                                        } : exercise
                                    )
                                } : training
                            );
                            return { ...oldData, trainings: updatedData };
                        }),
                    );
                    return patchResult;
                }

                const patchResult1 = updateCache('getTrainingFromProfessional', { ...query, userId: query.userId || idProfessional });
                const patchResult2 = updateCache('getMyWorkout', { ...query, userId: undefined });

                try {
                    await queryFulfilled;
                } catch {
                    patchResult1.undo();
                    patchResult2.undo();
                }
            }
        }),
        terminateTraining: builder.mutation({
            query: ({ idTraining }) => ({
                url: `/training/terminate/${idTraining}`,
                method: 'PUT',
            }),
            // invalidatesTags: ["trainingAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idTraining, idProfessional, series, seriesCompleted, query }, { dispatch, queryFulfilled }) => {
                const updateCache = (queryName, query) => {
                    const patchResult = dispatch(
                        trainingAPI.util.updateQueryData(queryName, query, (oldData) => {
                            const updatedData = Array.from(oldData?.trainings).map((training) =>
                                training._id === idTraining ? {
                                    ...training,
                                    isComplete: training.optionsSeriesTraining ? (seriesCompleted + 1 === series) : true,
                                    optionsSeriesTraining: training.optionsSeriesTraining ? {
                                        ...training.optionsSeriesTraining,
                                        seriesCompleted: training.optionsSeriesTraining.seriesCompleted + 1
                                    } : training.optionsSeriesTraining
                                } : training
                            );
                            return { ...oldData, trainings: updatedData };
                        }),
                    );
                    return patchResult;
                }
                const patchResult1 = updateCache('getTrainingFromProfessional', { ...query, userId: query.userId || idProfessional });
                const patchResult2 = updateCache('getMyWorkout', { ...query, userId: undefined });
                try {
                    await queryFulfilled;
                } catch {
                    patchResult1.undo();
                    patchResult2.undo();
                }
            }
        }),
        deleteBlockInTraining: builder.mutation({
            query: ({ idBlock }) => ({
                url: `/training/detele-block-in-training/${idBlock}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idBlock, query }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = Array.from(oldData?.trainings).map((training) => {
                            const updatedBlocks = training.blocksId.filter((block) => block._id !== idBlock);

                            if (updatedBlocks.length > 0) {
                                return { ...training, blocksId: updatedBlocks };
                            } else {
                                return null;
                            }
                        }).filter(training => training !== null);

                        return { ...oldData, trainings: updatedData };
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        terminateBlock: builder.mutation({
            query: ({ idBlock }) => ({
                url: `/blocks/terminate/${idBlock}`,
                method: 'PUT',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idBlock, idTraining, idProfessional, query }, { dispatch, queryFulfilled }) => {
                const updateCache = (queryName, query) => {
                    const patchResult = dispatch(
                        trainingAPI.util.updateQueryData(queryName, query, (oldData) => {
                            const updatedData = Array.from(oldData?.trainings).map((training) => {
                                if (training._id === idTraining) {
                                    const updatedBlocks = training.blocksId.map((block) => {
                                        if (block._id === idBlock) {
                                            return {
                                                ...block,
                                                isComplete: true,
                                            };
                                        }
                                        return block;
                                    });

                                    const isTrainingComplete = updatedBlocks.every((block) => block.isComplete);

                                    return {
                                        ...training,
                                        blocksId: updatedBlocks,
                                        isComplete: isTrainingComplete,
                                    };
                                }
                                return training;
                            });

                            return { ...oldData, trainings: updatedData };
                        }),
                    );

                    return patchResult;
                };

                const patchResult1 = updateCache('getTrainingFromProfessional', { ...query, userId: query.userId || idProfessional });
                const patchResult2 = updateCache('getMyWorkout', { ...query, userId: undefined });

                try {
                    await queryFulfilled;
                } catch {
                    patchResult1.undo();
                    patchResult2.undo();
                }
            },
        }),
        updateBlockInTraining: builder.mutation({
            query: (dataMutation) => ({
                url: `/blocks/update/${dataMutation?.blockId}`,
                method: 'PUT',
                body: dataMutation?.data
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ blockId, trainingId, query, ...result }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = Array.from(oldData?.trainings).map((training) => {
                            if (training._id === trainingId) {
                                const updatedBlocks = training.blocksId.map((block) => {
                                    if (block._id === blockId) {
                                        return { ...block, ...result?.data };
                                    }
                                    return block;
                                });
                                return { ...training, blocksId: updatedBlocks };
                            }
                            return training;
                        });
                        return { ...oldData, trainings: updatedData };
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),







    }),
});

export const {
    useGetNumberOfWorkoutsOtherUserQuery,
    useGetTrainingFromAthletaQuery,
    useCreateTrainingMutation,
    useDeleteTrainingMutation,
    useUpdateTrainingMutation,
    useUpdateExerciseInTrainingMutation,
    useDeleteExerciseInTrainingMutation,
    useGetTrainingFromProfessionalQuery,
    useGetMyWorkoutQuery,
    useTerminateExerciseMutation,
    useTerminateTrainingMutation,
    useDeleteBlockInTrainingMutation,
    useTerminateBlockMutation,
    useUpdateBlockInTrainingMutation
} = trainingAPI;